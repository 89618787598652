* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }

  .border-bottom2 {
    border-bottom: 1px solid #444;
  }

  .border-right {
    border-right: 1px solid #444;
  }

  .readMoreLink, .readMoreLink-dark {
    font-size: 1.25rem;
    padding: 15px;
    text-decoration: none !important;
  }

  .readMoreLink {
    border: 1px solid #fff;
    color: #fff !important;
  }

  .readMoreLink-dark {
    border: 1px solid #1f98f4;
    color: #1f98f4 !important;
  }

  .mainWrapper {
    background-color: #fff !important;
    padding: 4rem 0;
  }

  .blue-text {
    color: #1f98f4;
  }

  .primary-background {
    background-color: #1f98f4;
  }

  .servicesCol {
    position: relative;
    height: auto !important;
  }

  .readMore {
    position: absolute !important;
    bottom: 0 !important;
  }

  .home,
  .services,
  .about-us,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    background-image: url('/public/images/img-1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .about-us {
    background-image: url('/public/images/img-2.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    background-image: url('/public/images/img-8.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .manPowerIcon {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 820px) {
  
    .readMore {
      position: relative !important;
    }

    .servicesTxt {
      display: none;
    }

    .servicesCol h2 {
      text-align: center;
    }

    .mainWrapper {
      padding: 4rem;
    }
  }

  